<template>
  <li class="d-flex align-items-center">
    <div :class="{ 'is-busy': isDeleting }" class="form-check m-0 me-3 flex-grow-1">
      <input
        @change="selectionChange"
        v-model="isChecked"
        :value="metric.metricId"
        :id="`m-${metric.metricId}`"
        class="form-check-input"
        type="checkbox"
      >
      <label :for="`m-${metric.metricId}`" class="form-check-label">
        {{ metric.name }}
        <span class="smaller fst-italic text-muted">&mdash; {{ metric.unit }}</span>
      </label>
    </div>

    <BootstrapDropdown :class="{ 'is-busy': isDeleting }">
      <li
        @click="$emit('update')"
        class="dropdown-item small"
        role="button"
      >
        <fa-icon :icon="['fas', 'edit']" class="me-1"></fa-icon>
        Edit
      </li>
      <li><hr class="dropdown-divider"></li>
      <li
        @click="showConfirmModal = true"
        class="dropdown-item small text-danger"
        role="button"
      >
        <fa-icon :icon="['fas', 'trash-alt']" class="me-1"></fa-icon>
        Remove
      </li>
    </BootstrapDropdown>

    <portal to="modal">
      <transition name="modal">
        <ConfirmModal
          @confirm="deleteMetric"
          @close="showConfirmModal = false"
          v-model="showConfirmModal"
          v-if="showConfirmModal"
          :message="deleteConfirmationMessage"
        ></ConfirmModal>
      </transition>
    </portal>
  </li>
</template>

<script>
export default {
  name: 'MetricListItem',
  components: {
    BootstrapDropdown: () => import('@/components/bootstrap/BootstrapDropdown'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
  },
  props: {
    metric: {
      type: Object,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
  },
  watch: {
    selectedItems(newVal) {
      const { metricId } = this.metric;
      this.isChecked = newVal && newVal.indexOf(metricId) > -1;
    },
  },
  methods: {
    async deleteMetric() {
      this.showConfirmModal = false;
      this.isDeleting = true;
      try {
        const itemId = this.metric.metricId;
        await this.$store.dispatch('metric/deleteItem', itemId);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isDeleting = false;
      }
    },
    selectionChange() {
      const { isChecked } = this;
      this.$emit(isChecked ? 'select' : 'deselect');
    },
  },
  data() {
    return {
      isChecked: null,
      isDeleting: false,
      showConfirmModal: false,
      deleteConfirmationMessage: 'Deleting a metric will also erase all previous entries logged by your clients. This process cannot be undone. Do you want to proceed?',
    };
  },
};
</script>
